import { Link } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import errorIcon from '../images/Pages/errorIcon.png'
import icon from '../images/Pages/favicon.ico'
import logoHeader from '../images/Pages/logoHeader.png'

// styles
const mainStyles = {
  fontFamily: `NewJune, Avenir Next, Helvetica Neue, Arial, sans-serif`,
  lineHeight: `1.5`,
  color: `#51284f`,
  height: `100vh`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
}

const headerStyles = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  padding: `0px`,

  position: `absolute`,
  width: `100%`,
  height: `88px`,
  left: `0px`,
  top: `0px`,
}

const headerItemsContainerStyles = {
  display: `flex`,
  flexDirection: `row`,
  alignItems: `center`,
  padding: `0px 0px 0px 40px`,

  position: `static`,
  width: `100%`,
  height: `88px`,
  left: `calc(50% - 50%)`,
  top: `0px`,

  /* Gradients/Text/Headlines */
  background: `linear-gradient(90deg, #51284F 0%, #A50050 78.05%)`,
}

const headerLogoContainerStyles = {
  position: `static`,
  width: `200px`,
  height: `46px`,
  left: `40px`,
  top: `21px`,
  margin: `0px 24px`,
}

const headerLogoStyles = {
  width: `200px`,
  height: `46px`,
}

const errorContainerStyles = {
  display: `flex`,
  flexDirection: `row`,
  alignItems: `flex-start`,
  padding: `2rem`,
  gap: `1rem`,
}

const errorMessageContainerStyles = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `flex-start`,
  padding: `0px`,
}

const errorMessageStyles = {
  margin: `0 0 60px 0`,
}

const errorHeaderStyles = {
  fontWeight: `bold`,
}

const errorIconContainerStyles = {
  position: `static`,
  left: `0px`,
  top: `0px`,
}

const errorIconStyles = {
  width: `72px`,
  height: `72px`,
}

const secondHeaderStyles = {
  marginTop: `16px`,
}

// markup
const ServerErrorPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="shortcut icon" type="image/x-icon" href={icon} />
      <title>Error 500 | Laboral Kutxa</title>
    </Helmet>
    <main style={mainStyles}>
      <div style={headerStyles}>
        <div style={headerItemsContainerStyles}>
          <div style={headerLogoContainerStyles}>
            <img src={logoHeader} style={headerLogoStyles} alt="" />
          </div>
        </div>
      </div>
      <div style={errorContainerStyles}>
        <div style={errorIconContainerStyles}>
          <img src={errorIcon} style={errorIconStyles} alt="" />
        </div>
        <div style={errorMessageContainerStyles}>
          <div style={errorMessageStyles}>
            <h1 style={errorHeaderStyles}>Ha ocurrido un error</h1>
            <p>
              En estos momentos no podemos atender tu petición debido a que la página a la que estás intentando acceder
              no se encuentra disponible.
            </p>
            <h2 style={secondHeaderStyles}>Mientras tanto, te puede interesar...</h2>
            <ul>
              <li>
                Volver a intentarlo <Link to="/">recargando la página</Link>
              </li>
              <li>
                Llamar a Telebanka al <a href="tel:+34900929029">900 929 029</a> (de lunes a domingo de 07:00 a 24:00).
              </li>
            </ul>
          </div>
          <div style={errorMessageStyles}>
            <h1 style={errorHeaderStyles}>Akats bat gertatu da</h1>
            <p>
              Une honetan ezin dugu zure eskaerari erantzun, sartzen saiatzen ari zaren orria ez dagoelako erabilgarri.
            </p>
            <h2 style={secondHeaderStyles}>Bitartean, baliteke interesa izatea...</h2>
            <ul>
              <li>
                <Link to="/">Saiatu berriro orria kargatuz</Link>
              </li>
              <li>
                Deitu Telebanka <a href="tel:+34900929029">900 929 029</a> telefonora (astelehenetik igandera 07:00 -
                24:00).
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
  </>
)

export default ServerErrorPage
